<template>
  <div class="vb-quote" v-if="!loading">
    <router-link :to="{name: 'detail'}">
      <span class="vb-detail__back">indietro</span>
    </router-link>
    <h1 class="vb-heading h2 mt-3">Chi parteciperà al viaggio?</h1>
    <p>Inserisci con attenzione i dati dei viaggiatori così come indicati nel documento di identità che verrà usato per
      il viaggio. Tutti i campi sono obbligatori.
    </p>

    <div class="vb-quote__accordion section">
      <vb-toggle
          :vb_options="{
              id: 'vb-quote__accordion',
              btn_text: 'Riepilogo del pacchetto',
              visible: true,
            }"
      >
        <services
            class="p-0 mb-4"
            :services="quote.services"
        />

        <hr class="mb-4" />

        <services-insurance class="p-0 mt-0" />

        <div class="border-top mt-3 pt-3">
          <div class="d-lg-flex align-items-center justify-content-between">
            <span class="d-block d-lg-inline-block">
              Il prezzo che vedi è già comprensivo delle riduzioni o eventuali gratuità previste per i bambini.
              <br>
              * I trasferimenti non sono inclusi nei pacchetti per gli Stati Uniti. Per maggiori informazioni contattare l'assistenza.
            </span>
            <div class="d-block d-lg-inline-block text-right text--lg text-primary semibold">
              <span class="d-flex align-items-center justify-content-center justify-content-md-end">
                <span :class="availability.is_known && availability.total < quote.live_price ? 'text-dark' : ''">€ {{ quote.live_price }}</span>
                <a href="javascript:window.zE.activate()" v-if="availability.is_known && availability.total < quote.live_price" class="vb-quote__nocredit"
                   title="Contattaci">
                  <span v-html="'Credito non sufficiente?'" />
                  <vb-icon
                      class="vb-quote__nocredit-icon ml-1"
                      :name="'vb-chat'"
                      :color="'3b86ff'"
                      :size="18"
                  />
                </a>
              </span>
            </div>
          </div>
          <span class="d-block text-right text-secondary ">Attenzione, il prezzo della tua soluzione è stato aggiornato.</span>
        </div>
      </vb-toggle>

    </div>


    <div class="vb-pax" v-if="!loading">
      <validation-observer v-slot="{ invalid }">
        <div class="vb-pax__item p-3 mb-4" v-for="(pax, pIndex) in passengers.passengers"
             v-bind:key="'pax_'+pIndex">
  <!--        <h3>{{pax.id}} {{pax.passengerType}}</h3>-->
    <!--      <h5>età {{pax.age}}</h5>-->

          <b-row>
            <template v-for="(field, fIndex) in Object.values(pax.details.fields)">
              <div v-if="fIndex === 0" class="vb-pax__title col-12" v-bind:key="'field_static_0_'+pIndex+'_'+fIndex">
                <span>{{ field.label + ' ' + (pIndex + 1) }}</span>
                <div class="ml-2">
                  <span v-html="'(' + (pax.age <= 1 ? 'Infante' : (pax.age >= 18 ? 'Adulto' + ')' : 'Bambino'))" />
                  <span class="ml-1" v-html="pax.age != 30 ? pax.age + (pax.age > 1 ? ' anni' : ' anno') + ')' : ''" />
                </div>
              </div>

              <!-- Sesso-->
              <div v-if="field.name==='sesso'" class="vb-form col-6 col-lg-3 col-xl-2" v-bind:key="`field_static_${field.name}_${pIndex}_${fIndex}`">
                <validation-provider :rules="field.validate" :name="field.label" v-slot="{ errors }" :vid="'vvp_static_'+pIndex+'_'+fIndex" >
                  <b-form-group label-for="input" label-class="vb-label" v-bind:key="'b-form-group-static-'+pIndex+'_'+fIndex">
                    <template #label>
                      <span>{{ field.label }}</span>
                      <vb-icon v-if="field.tooltip" :name="'info'" :color="'adb5bd'" :size="14" class="ml-1" v-b-popover.hover.top="field.tooltip" />
                    </template>
                    <div class="vb-pax__select">
                      <vb-select :class="field.name" v-model="field.value" :vb_options="{options: field.options}" />
                    </div>
                  </b-form-group>
                  <span class="vee--errors">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <!-- Nome-->
              <div v-else-if="field.name==='nome'" class="vb-form col-12 col-lg-3" v-bind:key="`field_static_name_${pIndex}_${fIndex}`">
                <validation-provider :rules="field.validate" :name="field.label" v-slot="{ errors }" :vid="'vvp_static_'+pIndex+'_'+fIndex" >
                  <b-form-group label-for="input" label-class="vb-label" v-bind:key="'b-form-group-static-'+pIndex+'_'+fIndex">
                    <template #label>
                      <span>{{ field.label }}</span>
                      <vb-icon v-if="field.tooltip" :name="'info'" :color="'adb5bd'" :size="14" class="ml-1" v-b-popover.hover.top="field.tooltip" />
                    </template>
                    <div class="vb-pax__text">
                      <vb-input v-model="field.value" :vb_options="{ placeholder: field.placeholder, autocomplete: field.autocomplete, name: field.name }" />
                    </div>
                  </b-form-group>
                  <span class="vee--errors">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <!-- Cognome-->
              <div v-else-if="field.name==='cognome'" class="vb-form col-12 col-lg-3" v-bind:key="`field_static_lastname_${pIndex}_${fIndex}`">
                <validation-provider :rules="field.validate" :name="field.label" v-slot="{ errors }" :vid="'vvp_static_'+pIndex+'_'+fIndex" >
                  <b-form-group label-for="input" label-class="vb-label" v-bind:key="'b-form-group-static-'+pIndex+'_'+fIndex">
                    <template #label>
                      <span>{{ field.label }}</span>
                      <vb-icon v-if="field.tooltip" :name="'info'" :color="'adb5bd'" :size="14" class="ml-1" v-b-popover.hover.top="field.tooltip" />
                    </template>
                    <div class="vb-pax__text">
                      <vb-input v-model="field.value" :vb_options="{ placeholder: field.placeholder, autocomplete: field.autocomplete, name: field.name }" />
                    </div>
                  </b-form-group>
                  <span class="vee--errors">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <!-- Data di nascita-->
              <div v-else-if="field.name==='dataNascita'" class="vb-form col-12 col-lg-3 col-xl-2" v-bind:key="`field_static_birthdate_${pIndex}_${fIndex}`">
                <validation-provider :rules="field.validate" :name="field.label" v-slot="{ errors }" :vid="'vvp_static_'+pIndex+'_'+fIndex" >
                  <b-form-group label-for="input" label-class="vb-label" v-bind:key="'b-form-group-static-'+pIndex+'_'+fIndex">
                    <template #label>
                      <span>{{ field.label }}</span>
                      <vb-icon v-if="field.tooltip" :name="'info'" :color="'adb5bd'" :size="14" class="ml-1" v-b-popover.hover.top="field.tooltip" />
                    </template>
                    <div class="vb-pax__calendar">
                      <div class="vb-pax__birth">
                        <vb-input v-model="field.value" :vb_options="{
                          autocomplete: 'off',
                          placeholder: 'DD/MM/YYYY',
                          min: field.calendar.min,
                          max: field.calendar.max,
                          type: 'date',
                          value: field.value,
                        }"
                        @update="e => field.value = e" />
                      </div>
                    </div>
                  </b-form-group>
                  <span class="vee--errors">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="vb-form col-12 col-lg-3 col-xl-2" v-bind:key="'field_'+pIndex+'_'+fIndex" v-else-if="field.validate.required && !field.hidden">
                <validation-provider  :rules="field.validate" :name="field.label" v-slot="{ errors }" :vid="'vvp_'+pIndex+'_'+fIndex" >
                  <b-form-group
                      label-for="input"
                      label-class="vb-label"
                      v-bind:key="'b-form-group-'+pIndex+'_'+fIndex"
                  >
                    <template #label>
                      <span>{{ field.label }}</span>
                      <vb-icon v-if="field.tooltip" :name="'info'" :color="'adb5bd'" :size="14" class="ml-1" v-b-popover.hover.top="field.tooltip" />
                    </template>

                    <div class="vb-pax__calendar" v-if="field.type === 'calendar'">
                      <div class="vb-pax__birth">
                        <vb-input v-bind:key="'input_'+pIndex+'_'+fIndex" v-model="field.value"
                                  :vb_options="{
                                    placeholder: field.placeholder,
                                    disabled: true,
                                    autocomplete: field.autocomplete,
                                    name: field.name,
                                  }"
                        />
                        <span class="vb-pax__birth-toggle" @click="field.calendar.show_datepicker = !field.calendar.show_datepicker"></span>
                      </div>
                      <div class="vb-pax__datepicker">
                        <v-date-picker
                            :model-config="field.calendar.model_config"
                            :minDate="field.calendar.min"
                            :maxDate="field.calendar.max"
                            v-if="field.calendar.show_datepicker"
                            v-model="field.value"
                            @dayclick="() => { field.calendar.show_datepicker = false }"
                        />
                      </div>
                    </div>

                    <div class="vb-pax__text" v-else-if="field.type === 'text'">
                      <vb-input v-bind:key="'input_'+pIndex+'_'+fIndex" v-model="field.value"
                                :vb_options="{
                              placeholder: field.placeholder,
                              autocomplete: field.autocomplete,
                              name: field.name,
                            }"
                      />
                    </div>

                    <div class="vb-pax__select" v-else-if="field.type === 'select'">
                      <vb-select :class="field.name === 'tipPax' ? 'vb-select--readonly' : ''" v-bind:key="'select_'+pIndex+'_'+fIndex"
                                 v-model="field.value"
                                 :disabled="field.disabled"
                                 :vb_options="{
                                      options: field.options
                        }" /><span v-if="field.name === 'tipPax'" v-html="pax.age < 30 ? '(' + pax.age + (pax.age > 1 ? ' anni' : ' anno') + ')'  : ''" />
                    </div>

                    <div class="vb-pax__checkbox" v-else-if="field.type === 'checkbox'">
                      <vb-checkbox
                          v-model="field.value"
                          :vb_options="{
                            text: field.placeholder
                          }"
                      />
                    </div>

                  </b-form-group>

                  <span class="vee--errors">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </template>
          </b-row>
          
        </div>
        <div class="vb-pax__disclaimer">
          <b-row>
            <b-col xl="8" offset-xl="2">
              <vb-checkbox
                  v-model="check_disclaimer"
                  :vb_options="{
                text: 'Ho preso visione delle indicazioni di sicurezza e delle normative doganali e sanitarie di accesso a questa destinazione sul sito <a href=\'https://www.viaggiaresicuri.it\' target=\'_blank\'>Viaggiare sicuri</a> del Ministero degli esteri italiano.'
              }"
              />
              <vb-checkbox
                  v-model="check_disclaimer_beneficiary"
                  :vb_options="{
                text: 'Consapevole che, ai sensi dell\'art. 76 del DPR 445/2000, le dichiarazioni mendaci, la falsità e l\'uso di fatti falsi sono punite ai sensi del Codice penale e delle leggi speciali vigenti in materia, dichiaro di aver indicato - quale Beneficiario della prestazione (Viaggiatore principale) - un familiare conformemente a quanto previsto dal combinato disposto dell\'Art. 433 del Codice Civile e dell\'Art. 12 del D.P.R. del 22.12.1986 n. 917 e smi (TUIR).'
              }"
              />
            </b-col>
          </b-row>

          <router-link :to="{name: 'checkout'}" class="d-flex justify-content-center justify-content-lg-end" >
            <b-button class="vb-btn d-flex align-items-center" variant="info" :disabled="invalid || !check_disclaimer || !check_disclaimer_beneficiary" @click="goto_checkout">
              <vb-icon
                  v-if="invalid || !check_disclaimer || !check_disclaimer_beneficiary"
                  class="mr-2"
                  :name="'lock'"
                  :size=18
                  :color="'000'"
              />
              Vai al Checkout
            </b-button>
          </router-link>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import {custom_event, get_config, get_traveler_birth_range_dates, reverse_date} from '@utilities/commons'
import { pax_model } from '@resources/passenger'
import Services from "@alpitour/components/blocks/services.vue"
import ServicesInsurance from '@alpitour/components/blocks/detail/assicurazione-medico-bagaglio.vue'

export default {
  name: 'quote-block',

  props: {
    quote: {},
  },

  components: {
    'services': Services,
    'services-insurance': ServicesInsurance,
  },

  computed: {
    ...mapState('liveat', [
      'selected_room',
    ]),
    passengers: {
      get(){
        return this.$store.state.liveat.passengers
      },
      set(value){
        this.$store.commit('liveat/passengers', value)
      }
    },
    availability() {
      return this.get_availability()
    }
  },
  created(){
    this.pax_model = pax_model
    this.get_traveler_birth_range_dates = get_traveler_birth_range_dates
    this.reverse_date = reverse_date
  },
  data() {
    return {
      loading: true,
      check_disclaimer: false,
      check_disclaimer_beneficiary: false
    }
  },
  mounted() {

    this.model_pax()
    this.loading = false

  },
  methods:
  {
    model_pax() {


      if(
             !(this.passengers && Object.keys(this.passengers) == 0)
          && !(this.passengers && this.passengers.bookToken != this.quote.bookToken)
      ){

        // non devo "ricalcolare" il form se
        // c'è già passengers valorizzato e con lo stesso bookToken
        // (se cambia bookToken, quote diversa, form diverso)
        return

      }


      let paxs = []
      let force_mandatory = ['tipPax', 'nome', 'cognome', 'dataNascita']
      let force_mandatory_pax_1 = ['countCell', 'numCell']

      this.quote.compositions.passenger.forEach((pax) => {

        let pax_fields = []

        // sorto "alla rovescia" in modo che eventuali campi non mappati
        // finiscano in fondo e non in cima
        let field_name_order = Object.keys(this.pax_model).reverse()

        pax.details.fields
          .sort((a, b) => {
            return field_name_order.indexOf(b.name) - field_name_order.indexOf(a.name)
          })
          .forEach((field) => {

            if(pax.id == 1 && force_mandatory_pax_1.includes(field.name)) field.mandatory = true

            if(force_mandatory.includes(field.name)) field.mandatory = true

            if(field.name === 'sesso') field.mandatory = true

            // nel model voglio anche eventuali campi non mandatory ma già valorizzati di default
            // (che cmq non saranno mostrati nel form ma solo rimbalzati)
            if(!field.mandatory && !field.value) return

            pax_fields.push(this.pax_field_model(field, pax))
          })

        paxs.push({
          id: pax.id,
          passengerType: pax.passengerType,
          age: pax.age,
          details: {
            fields: pax_fields,
          }
        })

      })

      this.passengers = {
        bookToken: this.quote.bookToken,
        live_price: this.quote.live_price,
        id: this.quote.id,
        passengers: paxs
      }

    },

    pax_field_model(field, pax){

      let temp_field = (this.pax_model[field.name])? Object.assign({}, this.pax_model.default, this.pax_model[field.name]) : Object.assign({}, this.pax_model.default)

      // oggetti annidati vanno riassegnati
      if(temp_field.calendar) temp_field.calendar = Object.assign({}, temp_field.calendar)
      temp_field.validate = Object.assign({}, temp_field.validate) || {} // vee validate

      temp_field.name = field.name

      if(!temp_field.label || temp_field.label == '') temp_field.label = field.name

      if(field.mandatory && temp_field.validate.required == undefined) temp_field.validate.required = true

      if(field.name === 'dataNascita'){
        let dates = get_traveler_birth_range_dates(this.quote_departure_date(), pax.age)
        temp_field.calendar.min = dates.min
        temp_field.calendar.max = dates.max
      }

      if(temp_field.value_default) temp_field.value = temp_field.value_default({field: field, pax: pax})

      if(field.value) temp_field.value = field.value // valutare anche bool field.valid ?
      if(field.readOnly) temp_field.disabled = true
      
      //if(process.env.VUE_APP_MODE == 'development') if(this.fieldNameToDefaultValue(temp_field.name)) temp_field.value = this.fieldNameToDefaultValue(temp_field.name)

      return temp_field
    },

    quote_departure_date(){
      // ritorna la data di partenza del primo volo o del checkin se non ci sono voli
      return this.quote.services.flights[0].segments[0].routes[0].departureDateTime
    },

    goto_checkout() {

      custom_event('initiateCheckout', get_config('integration.company'), this.selected_room.quote_payload.totAmount, 'partnerBehaviour')

      //this.$store.commit('liveat/passengers', this.passengers)
      this.$store.commit('liveat/quote', this.quote)
    },

    fieldNameToDefaultValue(name) {

      // assegno dei valori di default in development

      const def = {
        cap: '60035',
        citta: 'Jesi',
        //codAzie: '', // popolato in automatico da alpitour
        codComune: 'Jesi',
        codFisc: 'GRNMHL86L11C615X',
        cognome: 'Girini',
        comuneNascitaCF: 'Chiaravalle',
        consenso: true, // non usato, alpitour-backend forza a vuoto
        countCell: '39',
        dataNascita: '11-07-1986',
        email: 'dev@salabam.com',
        indirizzo: 'via clementina 18',
        locNascita: 'Chiaravalle',
        nazionalita: 'ITA',
        nome: 'Michele',
        numCell: '3934288350',
        //numPrat: '', // popolato in automatico da alpitour
        //numProgPax: '', // popolato in automatico da alpitour
        prefCell: '', // (sarebbe il 393 di 3934288350) lasciare come arriva da alpitour e non mostrare
        provincia: 'AN',
        provNascitaCF: 'Ancona',
        sesso: 'M',
        statoEsteroNasc: 'Italia',
        statoEsteroResid: 'Italia',
        visto: true, // non usato, alpitour-backend forza a vuoto
      }


      if (def[name] !== undefined) return def[name]

      return ''
    },

  }
}
</script>

<style lang="scss">

  .vb-quote {
    margin-bottom: $grid-gutter-width*4;

    .vb-toggle__btn {
      background-color: rgba(255, 255, 255, 0.5) !important;
      font-size: $font-size-lg;
    }

    &__nocredit {
      color: $secondary;
      font-size: $font-size-lg;
      display: flex;
      align-items: baseline;
      justify-content: center;
      font-weight: $vb-font-weight-semibold;
      padding: .625rem 0;
      margin-left: $grid-gutter-width/3;

      @include media-breakpoint-up(xl) {
        font-size: $font-size-sm;
        padding: 0;

      }
    }
  }

  .vb-pax {

    &__item {
      background-color: $white;
      border-radius: 5px;

      .vb-form {
        margin-bottom: $grid-gutter-width/2;

        &__label {
          display: flex !important;
          align-items: center;
        }
      }

      .form-group {
        margin-bottom: 0;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      font-weight: $vb-font-weight-semibold;
      margin-bottom: $grid-gutter-width/2;
      color: $gray-700;
      padding: $grid-gutter-width/4 $grid-gutter-width $grid-gutter-width/2;
    }

    &__disclaimer {
      margin-top: $grid-gutter-width;

      @include media-breakpoint-up(md){
        margin-top: $grid-gutter-width*2;
      }

      .vb-check__title {
        font-size: $font-size-base;
      }

      .vb-check {
        margin-bottom: $grid-gutter-width;
        font-size: $font-size-sm;

        &:last-child {
          margin-bottom: $grid-gutter-width*2;
        }
      }
    }

    &__birth {
      position: relative;

      &-toggle {
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }

      input:disabled {
        background: $white;
      }
    }

    &__datepicker {
      margin-top: 1rem;
      position: absolute;
      z-index: 2;
    }

    &__select {
      display: flex;
      align-items: center;

      .vb-select {
        &--readonly {
          width: auto;
          padding-right: $grid-gutter-width/10;
        }
      }

      span {
        white-space: nowrap;
      }
    }

  }
    .services-insurance {
      padding: 0 !important;
      margin-top: 0;
      margin-bottom: 0;
      h2 {
        font-size: 1.25rem;
      }
    }

</style>